import { type SchemaDefinition } from "@tokenterminal/tt-types/client"
import { dequal } from "dequal"
import { type Atom, atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { type Result } from "../../utils/jotai/unwrap"
import { type FetchError } from "../fetch-error"

export type Endpoint = keyof SchemaDefinition
export type Options<T extends Endpoint> = SchemaDefinition[T]["options"]

export const fetchApiClientFamilyAtom = atomFamily(
  ({
    endpoint,
    options,
  }: {
    endpoint: Endpoint
    options?: SchemaDefinition[Endpoint]["options"]
  }) => {
    const fetchAtom = atom(async () => {
      try {
        const res = await globalThis.apiClient.request(
          endpoint,
          options as SchemaDefinition[Endpoint]["options"]
        )

        return {
          ok: res,
          err: null,
        }
      } catch (err) {
        return {
          ok: null,
          err: err as FetchError,
        }
      }
    })

    fetchAtom.debugLabel = `fetchApiClientAtom(${endpoint})`

    return fetchAtom
  },
  dequal
)

export function fetchApiClientAtom<T extends keyof SchemaDefinition>(
  endpoint: T,
  options?: SchemaDefinition[T]["options"]
) {
  return fetchApiClientFamilyAtom({ endpoint, options }) as Atom<
    Promise<Result<SchemaDefinition[T]["response"], FetchError>>
  >
}
