import { atom, type Getter } from "jotai"
import { atomWithRefresh } from "jotai/utils"

// create private key so nothing else can update the cache
export const SELF_UPDATE_KEY = Symbol.for("SELF_UPDATE_KEY")

export function withSuspendAtom<T>(getter: (get: Getter) => T | Promise<T>) {
  const cacheAtom = atom<T | null>(null)

  const refreshAtom = atomWithRefresh(async (get) => {
    const result = await getter(get)

    return result
  })
  refreshAtom.debugPrivate = true

  const atomWithCache = atom(
    (get, { setSelf }) => {
      const cached = get(cacheAtom)
      if (cached !== null) {
        return cached
      }

      const value = get(refreshAtom)

      if (value instanceof Promise) {
        return value.then((data) => {
          const cachedThenable: PromiseLike<Awaited<T>> = structuredClone(
            data
          ) as unknown as PromiseLike<Awaited<T>>

          // @ts-ignore -- fix types later
          cachedThenable.then = (onFulfilled: (args: Awaited<T>) => void) =>
            Promise.resolve().then(() => onFulfilled(data as Awaited<T>))
          setSelf({
            type: SELF_UPDATE_KEY,
            data: cachedThenable,
          })

          return cachedThenable
        })
      }

      return value
    },
    (
      _get,
      set,
      args?: {
        type: typeof SELF_UPDATE_KEY
        data: Awaited<T>
      }
    ) => {
      if (args?.type === SELF_UPDATE_KEY) {
        set(cacheAtom, args.data)
        return
      }

      set(cacheAtom, null)
      return set(refreshAtom)
    }
  )

  atomWithCache.debugPrivate = true

  return atomWithCache
}
