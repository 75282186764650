import { Sun, Moon, Monitor } from '@phosphor-icons/react'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { usePersistedState } from './usePersistedState'

export const themeOptions = {
  light: {
    label: 'Light',
    icon: Sun,
  },
  dark: {
    label: 'Dark',
    icon: Moon,
  },
  system: {
    label: 'System',
    icon: Monitor,
  },
} as const

export type ThemePreference = keyof typeof themeOptions

export type ThemeMode = 'light' | 'dark'

type ThemeContextType = {
  preferredTheme: ThemePreference | undefined
  theme: ThemeMode | undefined
  setTheme: (newTheme: ThemeMode) => void
  setPreferredTheme: (newPreferredTheme: ThemePreference) => void
}

const ThemeContext = createContext<ThemeContextType | null>(null)

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [preferredTheme, _setPreferredTheme] = usePersistedState<
    ThemePreference | undefined
  >('theme-preference', 'dark', 'localStorage')
  const [theme, _setTheme] = usePersistedState<ThemeMode | undefined>(
    'theme',
    // TODO: Consider using window.__tt_theme as default
    undefined,
    'localStorage',
  )

  const updateBodyClass = (newTheme: ThemeMode) => {
    const body = document.querySelector('body')
    if (!body) return

    body.classList.remove('theme-light', 'theme-dark')
    body.classList.add(`theme-${newTheme}`)
  }

  const setPreferredTheme = useCallback(
    (newPreferredTheme: ThemePreference) => {
      const body = document.querySelector('body')
      if (!body) return

      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)')

      body.classList.remove('theme-light', 'theme-dark')
      let newTheme: ThemeMode
      if (newPreferredTheme && newPreferredTheme !== 'system') {
        newTheme = newPreferredTheme
      } else if (newPreferredTheme && prefersDarkMode.matches) {
        newTheme = 'dark'
      } else {
        newTheme = 'light'
      }
      updateBodyClass(newTheme)

      _setTheme(newTheme)
      _setPreferredTheme(newPreferredTheme)
    },
    [_setPreferredTheme, _setTheme],
  )

  const setTheme = useCallback(
    (newTheme: ThemeMode) => {
      updateBodyClass(newTheme)
      _setTheme(newTheme)
    },
    [_setTheme],
  )

  useEffect(() => {
    // Only listen to color scheme changes if the user is using the system theme
    if (preferredTheme !== 'system') {
      return
    }

    const handleMediaChange = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? 'dark' : 'light')
    }
    const media = window.matchMedia('(prefers-color-scheme: dark)')
    media.addEventListener('change', handleMediaChange)
    return () => media.removeEventListener('change', handleMediaChange)
  }, [preferredTheme, setTheme])

  const context: ThemeContextType = useMemo(
    () => ({ preferredTheme, theme, setTheme, setPreferredTheme }),
    [preferredTheme, theme, setTheme, setPreferredTheme],
  )

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}

// Script needs to be part of HTML to reduce screen flickering
export const AvoidThemeFlickerScript = memo(() => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
        (function() {
          const savedTheme = JSON.parse(localStorage.getItem('tt.theme'))
          if (savedTheme) {
            document.body.classList.add('theme-' + savedTheme)
            window.__tt_theme = savedTheme
          } else {
            // Default to system theme
            // localStorage.setItem('tt.theme-preference', JSON.stringify("system"))
            // const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
            // const preferredTheme = prefersDarkMode.matches ? 'dark' : 'light'
            // localStorage.setItem('tt.theme', JSON.stringify(preferredTheme))
            // document.body.classList.add('theme-' + preferredTheme)

            // Default to dark theme
            localStorage.setItem('tt.theme', JSON.stringify('dark'))
            localStorage.setItem('tt.theme-preference', JSON.stringify('dark'))
            document.body.classList.add('theme-dark')
            window.__tt_theme = 'dark'
          }
        })()
      `,
    }}
  />
))

AvoidThemeFlickerScript.displayName = 'AvoidThemeFlicker'
