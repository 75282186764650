// TODO: Consolidate with other usePersistedState.ts hook
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

const voidFn = () => {
  /* void */
}

const dummyStorage: Storage = {
  getItem: () => null,
  setItem: voidFn,
  removeItem: voidFn,
  clear: voidFn,
  key: () => null,
  length: 0,
}

const BASE_KEY = 'tt'

const createStorageKey = (key: string) => `${BASE_KEY}.${key}`

type StorageType = 'sessionStorage' | 'localStorage'

const getStorageKey = (key: string) =>
  key.split('.')[0] === BASE_KEY ? key : createStorageKey(key)

const getStorage = (storage: StorageType) =>
  typeof window !== 'undefined' && typeof window[storage] !== 'undefined'
    ? window[storage]
    : dummyStorage

// use typescript overloading to return proper types
function getStorageValue<T>(
  storageType: StorageType,
  key: string,
  defaultValue: T,
  updateState: Dispatch<SetStateAction<T>>,
): undefined
function getStorageValue<T>(
  storageType: StorageType,
  key: string,
  defaultValue: T,
  updateState?: undefined,
): T
function getStorageValue<T>(
  storageType: StorageType,
  key: string,
  defaultValue: T,
  updateState?: Dispatch<SetStateAction<T>>,
): T | undefined {
  const item = getStorage(storageType).getItem(key)

  if (item) {
    if (item !== 'undefined') {
      const value = JSON.parse(item)

      if (updateState) {
        updateState(value)
      } else {
        return value
      }
    }
  }

  if (!updateState) {
    return defaultValue
  }
}

export function usePersistedState<T extends unknown>(
  givenKey: string,
  defaultValue: T,
  storageType: StorageType = 'sessionStorage',
): [T, (state: T | ((prevState: T) => T)) => void] {
  const keyRef = useRef(getStorageKey(givenKey)) // Don't allow dynamically updating key
  const storageRef = useRef(storageType) // Don't allow dynamically updating storage type
  const [state, setState] = useState<T>(
    // @ts-ignore - admin ui breaks - think it's old typscript version
    global.isServerOrFirstHydration
      ? defaultValue
      : getStorageValue(storageType, keyRef.current, defaultValue),
  )

  const updateStorage = useCallback((state: SetStateAction<T>) => {
    setState((currentState) => {
      const newState =
        typeof state === 'function'
          ? (state as (prevState: T) => T)(currentState)
          : state

      getStorage(storageRef.current).setItem(
        keyRef.current,
        JSON.stringify(newState),
      )

      return newState
    })
  }, [])

  useEffect(() => {
    getStorageValue(storageType, keyRef.current, defaultValue, setState)
  }, [setState, storageType, defaultValue])

  return [state, updateStorage]
}
